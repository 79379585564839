.clickable {
    cursor: pointer;
}
.red {
    color: red
}
.green {
    color: green;
}

.itemListBadge {
    padding: 5px 10px;
    margin: 2px 5px;
}
.itemListBadge a {
    color: white;
    text-decoration: underline;
}
.itemListBadge .badge {
    margin-left: 5px;
    cursor: pointer;
}
.treeview {
    list-style-type: none;
}
.horizontal-line { 
    width:100%;
    border-bottom: 1px solid #000; 
    line-height:0.1em; 
}
.list-group-hover .list-group-item:hover {
    background-color: #f5f5f5;
}