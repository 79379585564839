.department-bedlist-table,
.department-bedlist-table tr, 
.department-bedlist-table th,
.department-bedlist-table td {
    border: 1px solid black;
}

.text-vertical-center {
    vertical-align: middle;
}

.page-title {
    text-align: center;
    padding: 0;
    margin: 0;
}
.patient-name {
    font-size: 14px;
    font-weight: bold;
}
.health-record-summary {
    font-size: 12px;
    line-height: 14px;
}

@media print {
    .no-print, .no-print * {
        display: none !important;
    }
    .page-container {
        margin-top: 5px;
        width: 99%;
    }
    .page-title {
        position: absolute;
        top: 5px;
        left: 0;
        right: 0;
        z-index: 100000 !important;
    }
    @page {
        size: A4;
        orientation: portrait;
        margin: 10mm;
    }
}