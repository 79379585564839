.timeline-item-symbol {
    font-size: 24px;
    margin: 4px;
}
.highlighted {
    background-color: #fdff9a;
}
.medication-dispension-badge {
    font-size: 14px !important;
}
.tag-badge {
    border-radius: 8px;
    display: inline-block;
    padding: 0px 4px;
    font-weight: 600;
    font-size: 14px;
    border: 1px solid grey;
}
.table-row-pregnancy-active {
    background-color: #bf8;
}
.react-confirm-alert-overlay {
    z-index: 1500 !important;
}
#healthrecord-search-navbar {
    z-index: 1050;
}
.info {
    cursor: help;
}